import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { GeneralCMSResult } from '../types/cms/generalTypes';
import { Heading } from 'legacy-ui';
import { Text } from 'legacy-ui';

interface PageCollectionItemProps {
  href: string;
  imageSrc: string;
  imageAlt: string;
  location?: string;
  heading: string;
  text?: string;
  textPrepend?: string;
  isUpcoming?: boolean;
  generalTexts?: GeneralCMSResult;
}

const StyledPageCollectionItem = styled.div`
  a {
    text-align: left;
    display: block;
    img {
      border-radius: 8px;
    }
    &:hover {
      opacity: 0.9;
    }
  }
  h3 {
    margin: 4px 0 8px 0;
  }
  p {
    span {
      font-weight: 600;
      &:after {
        content: '•';
        margin: 0 12px;
      }
    }
  }
`;

const StyledPageCollectionItemText = styled.div`
  padding: 12px 8px;
  p {
    svg {
      margin-right: 4px;
    }
  }
`;

const StyledPageCollectionImageContainer = styled.div`
  position: relative;
`;

const StyledPageCollectionImageInnerLabel = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  p {
    background: #ffed48;
    color: ${(props) => props.theme.color.gray90};
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 600;
  }
`;

function PageCollectionItem({ href, imageSrc, imageAlt, location, heading, text, textPrepend, isUpcoming, generalTexts }: PageCollectionItemProps) {
  return (
    <StyledPageCollectionItem>
      <Link href={href} passHref>
        <a>
          <StyledPageCollectionImageContainer>
            <Image src={imageSrc} alt={imageAlt} width={384} height={216} layout="responsive" priority />
            {isUpcoming && (
              <StyledPageCollectionImageInnerLabel>
                <Text size="s">{generalTexts?.Misc.Upcoming}</Text>
              </StyledPageCollectionImageInnerLabel>
            )}
          </StyledPageCollectionImageContainer>
          <StyledPageCollectionItemText>
            {location && (
              <Text size="s">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {location}
              </Text>
            )}
            <Heading size="xxs" tag="h3">
              {heading}
            </Heading>
            {text && (
              <Text>
                {textPrepend && <span>{textPrepend}</span>}
                {text}
              </Text>
            )}
          </StyledPageCollectionItemText>
        </a>
      </Link>
    </StyledPageCollectionItem>
  );
}

export default PageCollectionItem;

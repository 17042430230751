import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import ResponsiveIframe from '../../components/ResponsiveIframe';
import { Text } from 'legacy-ui';
import Container from '../../sections/common/Container';

interface RichTextProps {
  fromCMS?: string;
  fromTiny?: boolean;
  spacing?: string;
}

const StyledRichText = styled.section<RichTextProps>`
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 112px 0;
  }
  ${(props) => {
    if (props.spacing === 'noTop') {
      return `
        padding-top: 0 !important;
    `;
    }
  }}
  p {
    margin-bottom: 28px;
  }
  h3 {
    margin-bottom: 12px;
    word-break: break-word;
    hyphens: auto;
  }
  ul,
  ol {
    line-height: 0;
    margin-bottom: 28px;
    margin-left: 28px;
    li {
      line-height: 28px;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    ul,
    ol {
      margin-bottom: 0;
      > li:first-child {
        margin-top: 8px;
      }
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  img {
    margin: 56px 0;
    border-radius: 8px;
  }
  a {
    text-decoration: underline;
    &:hover {
      opacity: 0.9;
    }
  }
  code {
    font-family: monospace;
    background: ${(props) => props.theme.color.gray20};
    color: ${(props) => props.theme.color.red70};
    padding: 4px 8px;
    border-radius: 8px;
  }
  pre {
    margin: 0 0 28px 0;
  }
  blockquote {
    margin: 0 0 28px 0;
    border-left: 4px solid ${(props) => props.theme.color.gray30};
    padding: 16px 16px 16px 48px;
    position: relative;
    &:before {
      content: '“';
      font-family: serif;
      font-size: 48px;
      font-weight: bold;
      color: ${(props) => props.theme.color.gray30};
      position: absolute;
      top: 8px;
      left: 12px;
    }
    p {
      margin-bottom: 0;
    }
  }
`;

const RichText = ({ fromCMS, fromTiny, spacing }: RichTextProps) => {
  if (!fromCMS) {
    return null;
  }
  return (
    <Container>
      <StyledRichText spacing={spacing}>
        <Markdown
          options={{
            overrides: {
              h1: {
                component: Heading,
                props: {
                  size: 'xl',
                  tag: 'h1',
                },
              },
              h2: {
                component: Heading,
                props: {
                  size: 'l',
                  tag: 'h2',
                },
              },
              h3: {
                component: Heading,
                props: {
                  size: fromTiny ? 'xs' : 'r',
                  tag: 'h3',
                },
              },
              h4: {
                component: Heading,
                props: {
                  size: 's',
                  tag: 'h4',
                },
              },
              h5: {
                component: Heading,
                props: {
                  size: 'xs',
                  tag: 'h5',
                },
              },
              h6: {
                component: Heading,
                props: {
                  size: 'xss',
                  tag: 'h6',
                },
              },
              p: Text,
              iframe: ResponsiveIframe,
              a: {
                component: 'a',
                props: {
                  target: '_self',
                },
              },
            },
          }}
        >
          {fromCMS}
        </Markdown>
      </StyledRichText>
    </Container>
  );
};

export default RichText;

import React from 'react';
import PropertyCard from './PropertyCard';
import PageSelect from 'components/PageSelect';
import { useRouter } from 'next/router';
import { getRoute } from 'utils/languages';
import { LanguageKey } from 'types/common';
import { SearchPropertiesResponse } from 'tenant-client';

export type PropertiesGridProps = {
  columns: number;
  isClientSide?: boolean;
} & SearchPropertiesResponse;

const PropertiesGrid = ({ properties, columns, page, pageSize, totalPropertiesCount, isClientSide = false }: PropertiesGridProps) => {
  const router = useRouter();

  const gotoPage = (page: number) => {
    const { pathname, locale, query } = router;
    const { page: _, ...restQuery } = query;
    const newQuery = page === 1 ? restQuery : { ...query, page };
    router.push({ pathname: getRoute(pathname, locale as LanguageKey), query: newQuery }, undefined, { shallow: isClientSide });
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <div className="flex justify-center md:justify-start mb-14 w-full">
        <div className={`grid grid-cols-1 gap-7 md:grid-cols-${columns} w-full`}>
          {properties.map((property) => (
            <PropertyCard key={property.id} property={property} />
          ))}
        </div>
      </div>
      {totalPropertiesCount > pageSize && (
        <PageSelect
          page={page}
          totalResults={totalPropertiesCount}
          pageSize={pageSize}
          setPage={gotoPage}
          SEO={{ canonicalPath: router.asPath.split('?')[0] }}
        />
      )}
    </>
  );
};

export default PropertiesGrid;

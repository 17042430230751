import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBedFront, faTableCellsLarge } from '@fortawesome/pro-regular-svg-icons';
import { getImageFromApiURl } from 'utils/http';
import { formatAsCurrency } from 'utils/currency';
import { createCompanyLink, createPropertyGroupLink, createPropertyLink } from 'utils/links';
import { useRouter } from 'next/router';
import { LanguageKey } from 'types/common';
import { format, parseISO } from 'date-fns';
import PropertyImageThumbnail from 'components/PropertyImageThumbnail';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import { useTranslation } from 'next-i18next';
import { AvailabilityStatus, SearchProperty } from 'tenant-client';

type PropertyCardProps = {
  property: SearchProperty;
};

const formatAvailableFrom = (availableFrom: string | undefined | null, nowTranslation: string | undefined | null) => {
  return availableFrom ? (parseISO(availableFrom) < new Date() ? nowTranslation : `${format(parseISO(availableFrom), 'dd-MM-Y')}`) : '-';
};

const PropertyCard = ({ property }: PropertyCardProps) => {
  const {
    id,
    name,
    city,
    size,
    rooms,
    availableFrom,
    price,
    image,
    unit,
    companyId,
    propertyGroupId,
    companyName,
    propertyGroupName,
    availabilityStatus,
    crawled,
    propertySlug,
    propertyGroupSlug,
    companySlug,
  } = property;
  const { locale } = useRouter();
  const { theme } = useWhiteLabel();
  const { t } = useTranslation('general');

  const getTransactionStatusPill = (availabilityStatus: AvailabilityStatus) => {
    if (availabilityStatus === 'Available') {
      return (
        <span
          style={{ backgroundColor: theme.color.availableCardPillBackgroundColor, color: theme.color.availableCardPillTextColor }}
          className="absolute left-6 top-6 py-1 px-4 rounded-[70px] text-white text-xs font-semibold leading-tight"
        >
          {t('available')}
        </span>
      );
    }

    return (
      <span
        style={{ backgroundColor: theme.color.reservedCardPillBackgroundColor, color: theme.color.reservedCardPillTextColor }}
        className="absolute left-6 top-6 py-1 px-4 rounded-[70px] text-white text-xs font-semibold font-['Poppins'] leading-tight"
      >
        {t('reserved')}
      </span>
    );
  };

  return (
    <div className="flex relative flex-col h-[448px] bg-white rounded-lg border-solid border border-gray-200">
      <div className="relative w-full h-2/5 rounded-t-lg overflow-hidden">
        <div className="absolute w-full h-full">
          <Link href={createPropertyLink(id, name, propertySlug, locale as LanguageKey)} passHref>
            <a>
              <PropertyImageThumbnail
                src={getImageFromApiURl(String(image?.name), 384, true, crawled)}
                alt={name}
                backgroundColor={theme.color.propertyCardBackgroundColor}
                logoColor={theme.color.propertyCardLogoColor}
              />
            </a>
          </Link>
        </div>
      </div>
      <div className="flex flex-col p-6">
        <Link href={createPropertyLink(id, name, propertySlug, locale as LanguageKey)} passHref>
          <a className="mb-1 text-xl font-semibold truncate">{name}</a>
        </Link>
        <span className="mb-1 text-sm text-neutral-700 ">{city}</span>
        <Link href={createPropertyGroupLink(propertyGroupId, propertyGroupName, propertyGroupSlug, locale as LanguageKey)} passHref>
          <a className="text-neutral-700 mb-4 underline text-sm visited:text-neutral-700">{propertyGroupName}</a>
        </Link>
        <div className="mb-4">
          <FontAwesomeIcon icon={faTableCellsLarge} className="mr-2" />
          <span className="mr-3 text-neutral-800 ">{size || '-'} m²</span>
          <FontAwesomeIcon className="mr-2" icon={faBedFront} />
          <span className="text-neutral-800">{rooms || '-'}</span>
        </div>
        <span className="mb-3 text-gray-500 text-sm ">
          {t('moveInDate')}: {formatAvailableFrom(availableFrom, t('now'))}
        </span>
        <span className="mb-1 text-emerald-950 text-xl font-semibold">{formatAsCurrency(Number(price), String(unit))}</span>
        <Link href={createCompanyLink(companyId, companyName, companySlug, locale as LanguageKey)} passHref>
          <a className="text-gray-500 text-sm underline visited:text-gray-500">{companyName}</a>
        </Link>
      </div>
      {getTransactionStatusPill(availabilityStatus)}
    </div>
  );
};

export default PropertyCard;

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function ResponsiveIframe({ ...rest }: any) {
  return (
    <Wrapper>
      <iframe {...rest} />
    </Wrapper>
  );
}

export default ResponsiveIframe;

import { useRef } from 'react';
import styled from 'styled-components';
import { GeneralCMSResult } from '../types/cms/generalTypes';
import PageSelect from './PageSelect';

const DEFAULT_PAGE_SIZE = 10;

const StyledPageCollection = styled.div`
  margin-bottom: 112px;
`;

const PageCollectionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  > div {
    flex: 0 0 100%;
    margin-bottom: 48px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      flex: 0 0 calc(100% / 2 - 8px);
      margin-right: 16px;
      margin-bottom: 56px;
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      flex: 0 0 calc(100% / 3 - 16px);
      margin-right: 24px;
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) and (max-width: ${(props) => props.theme.breakpoint.lg}) {
      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }
    }
  }
`;

const PageCollectionEmpty = styled.div`
  margin-bottom: 64px;
  text-align: center;
  color: ${(props) => props.theme.color.gray60};
`;

interface PageCollectionProps {
  children: React.ReactNode;
  page?: number;
  setPage?: (page: number) => void;
  totalResults?: number;
  isLoading?: boolean;
  pageSize?: number;
  generalTexts: GeneralCMSResult;
}

function PageCollection({ children, page, setPage, totalResults, pageSize = DEFAULT_PAGE_SIZE, generalTexts }: PageCollectionProps) {
  const ref = useRef<HTMLDivElement>(null);

  function goToPage(page: number) {
    setPage && setPage(page);
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  if (!totalResults) {
    return <PageCollectionEmpty>{generalTexts.Misc.NoResultsFound}</PageCollectionEmpty>;
  }

  return (
    <StyledPageCollection ref={ref}>
      <PageCollectionList>{children}</PageCollectionList>
      {page && setPage && totalResults > pageSize && <PageSelect pageSize={pageSize} page={page} setPage={goToPage} totalResults={totalResults} />}
    </StyledPageCollection>
  );
}

export default PageCollection;
